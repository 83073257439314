import {mapMutations, mapGetters} from "vuex";

export default {
  name: "slider",
  components: {},
  props: {
    current: {
      type: Number,
      default: () => null,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    current: {
      immediate: true,
      handler(newVal) {
        if( newVal || newVal === 0 ){
          this.setIndex(this.current);
        }
      }
    },
  },

  data() {
    return {
      currentIndex: null,
      viewer: null
    }
  },

  mounted() {

  },
  created() {
    this.setIndex(this.current);
  },
  computed: {
    ...mapGetters({

    }),
    currentImage(){
      if(this.currentIndex !== null){
        return this.list[this.currentIndex]
      }
      else return []
    }
  },
  methods: {
    ...mapMutations({}),
    next(){
      this.currentIndex !== this.list.length - 1 ? this.currentIndex++ : this.currentIndex = 0
    },
    prev(){
      this.currentIndex !== 0 ? this.currentIndex-- : this.currentIndex = this.list.length - 1
    },
    setIndex(index){
      this.currentIndex = index;
    },
}
}
