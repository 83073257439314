import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from 'aos';
import 'aos/dist/aos.css';

//sections
import gallery from "@/modules/projects/section/gallery/index.vue";

export default {
  name: "projects",
  components:{
    gallery
  },
  data(){
    return{

    }
  },
  created() {
    this.resetSkip()
    this.getGallery()
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed:{
    ...mapGetters({
      gallery: 'home/gallery',
      limit: 'home/limit',
    }),
  },

  methods:{
    ...mapActions({
      getGallery: 'home/GET_GALLERY',
    }),
    ...mapMutations({
      changeSkip: 'home/CHANGE_PAGE_SKIP',
      resetSkip: 'home/RESET_SKIP'
    }),
    loadMore(){
      this.changeSkip()
      this.getGallery()
    },

  }
}