import {mapMutations, mapGetters} from "vuex";
import slider from "@/modules/projects/section/gallery/slider/index.vue";

export default {
  name: "gallery",
  components: {
    slider,
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      currentIndex: null
    }
  },

  mounted() {

  },
  created() {
  },
  computed: {
    ...mapGetters({

    })
  },
  methods: {
    ...mapMutations({}),
    setIndex(index){
      this.currentIndex = index
      setTimeout(()=>{
        this.currentIndex = null
      },100)
    },

  }
}
